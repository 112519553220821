.error-message {
  font-size: 0.75rem!important;
  text-align: left!important;
  margin-top: 5px!important;
}

//TODO: find out if we need this, move if needed
#feedback-form {
  .MuiFormHelperText-root.Mui-error {
    white-space: nowrap;
  }
}

.checkbox-label {
  font-size: 14px!important;
}

a {
  text-decoration: none;
}

p {
  word-break: break-word;
}
