.upload-photos-paper {
    margin-top: 10px;
    text-align: center;
    padding: 25px;
    margin-bottom: 25px;
}

.crop-area {
    min-height: 162px;
    border: 1px dashed #707070;
    border-radius: 2px;
    display: flex;
    align-items: center;
    background-color: #333339;
    border: none;
    flex-direction: column;
}

.dropzone-area {
    min-height: 162px;
    border: 1px dashed #707070;
    border-radius: 2px;
    display: flex;
    align-items: center;

    &.has-file {
        background-color: #333339;
        border: none;
    }

    .dropzone-area-icon {
        color: #707070;
        font-size: 5rem;
        margin: auto;
    }
}

.drag-and-drop-text {
    color: #b2b2b2;
    font-size: 17px !important;
    margin-top: 10px !important;
}

#upload-photos-btn,
#upload-documents-btn {
    height: 30px;
    font-size: 16px;
    margin: auto;
}

.uploaded-file {
    width: 40%;
    margin: auto;
}

.updating-text {
    margin-bottom: 5px !important;
    font-size: 14px !important;
    color: #efefef !important;
}

.selected-file-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    align-self: flex-end;
    margin-right: 12px;
    margin-bottom: 10px;

    .file-name {
        font-weight: bold;
        font-size: 16px !important;
    }

    #file-delete {
        svg {
            width: 20px !important;
        }
    }

    #file-done {
        color: #fe6502;
    }
}
