.collection-add-obj {
  background-color: #383838;
  border-radius: 2px;
  margin-bottom: 10px;
  padding: 7px 7px 7px 10px;
  cursor: pointer;

  .title {
    font-size: 18px;
    font-weight: 400;
  }

  .subtitle {
    color: #B2B2B2;
    font-size: 15px;
    font-weight: 400;
  }

  &.existing-obj {
    border-left: 5px solid #029BFE;
  }

  &.new-obj {
    border-left: 5px solid #FE6502;
  }

  &:hover {
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  }
}

.select-objects-autocomplete {
  p {
    margin-bottom: 5px;
  }

  .MuiChip-root  {
    color: white;
    background-color: #707070;
    height: 25px;
  }

  .MuiChip-deleteIcon {
    height: 17px;
  }

  .MuiAutocomplete-inputRoot {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 15px;
  }
}


