#user-menu-tooltip {

  .MuiTooltip-tooltip {
    box-shadow: 0 3px 10px #00000052;
  }

  :first-child {
    background-color: #383838;
    span {
      &:last-child {
         color: #383838;
      }
    }
  }
}

.user-menu-popover {
  .user-name {
    color: #FFFFFF;
    font-size: 16px;
    margin-right: 5px;
  }

  .menu-items {
    padding-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;

    .divider {
      width: 80%;
      height: 1px;
      background-color: #707070;
      margin: 10px 0;
    }

    .menu-item {

      font-size: 16px;
      color: #B2B2B2;
      display: flex;
      cursor: pointer!important;
      margin-bottom: 10px;

      span {
        color: #B2B2B2!important;
      }

      span:hover {
        color: white!important;
        text-decoration: underline;
      }
 
      svg {
        margin-right: 5px;
      }

      &:hover {
        color: white;
      }
    }
  }
}


.new-notifications {
  width: 9px;
  height: 9px;
  border-radius: 10px;
  background-color: #FE6502;
  position: absolute;
  margin-left: -8px;
  margin-top: 19px;
}
