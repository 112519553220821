.shared-with-me-card {
  width: 24%;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 35px;
  margin-left: 0.5%;
  margin-right: 0.5%;
  padding: 30px 30px;

  &.card-link {
    padding: 0;
  }

  .collector-details {
    display: flex;
    align-items: center;
    padding-top: 0;
    margin-bottom: 15px;

    .collector-name {
      font-size: 16px;
      color: #FFFFFF;
      font-weight: 400;
      margin-left: 10px;
    }

    .collector-avatar {
      width: 40px;
      height: 40px;
      border-radius: 40px;
    }
  }

  .MuiCardContent-root {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  .card-title {
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    margin-bottom: 7px;
  }

  .description {
    color: #B2B2B2;
    font-size: 13px;
    margin-bottom: 0;
  }
}

@media (max-width: 1280px) {
  .shared-with-me-card {
    width: 32.33%;
  }
}

@media (max-width: 960px) {
  .shared-with-me-card {
    width: 49%;
  }
}

@media (max-width: 600px) {
  .shared-with-me-card {
    width: 99%;
  }
}
