.pincode-input-container {
  text-align: center;
}

.pincode-input-text {
  background-color: #383838!important;
  border: none!important;
  color: white;
  width: 40px!important;
}
