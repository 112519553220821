.shared-with-me-container {
  padding: 0 30px 15px;
}

.header-fixed {
  position: sticky;
  top: 65px;
  z-index: 3;
  padding-top: 15px;
  padding-bottom: 20px;
  width: 100.1%;
}
