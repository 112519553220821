.add-object-container {
  padding: 15px 30px;
}

.actions {
  margin-top: -35px;
  text-align: right;
  padding-bottom: 20px;
  position: sticky;

  button {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}
