.MuiSnackbar-root {
  top: 70px!important;
  right: 0!important;
  box-shadow: 0 3px 10px #00000033;
}

.custom-snackbar {

  display: flex;
  align-items: center;
  min-height: 53px;
  border-radius: 2px 0 0 2px;

  .icon-box {

    height: inherit;
    min-height: inherit;
    display: flex;
    align-items: center;
    border-radius: 2px 0 0 2px;
    width: 45px;

    svg {
      margin: auto;
    }
  }

  .message {
    background-color: #383838;
    min-height: inherit;
    display: flex;
    align-items: center;
    padding: 0 10px;

    p {
      color: #B2B2B2;
      font-size: 17px;
    }

    svg {
      margin-left: 10px;
      color: #B2B2B2;
      cursor: pointer;
    }
  }

  &.success {
    .icon-box {
      background-color: #FE6502;
    }
  }

  &.error {
    .icon-box {
      background-color: #B32222;
    }
  }
}
