//TODO: find out if we need this
.MuiRadio-colorSecondary-5436.Mui-disabled {
  color: white!important;
}

#share-artifact-to-persons-form {
  .MuiChip-root  {
    color: white;
    height: 25px;
  }

  .MuiChip-deleteIcon {
    height: 17px;
  }

  .MuiAutocomplete-inputRoot {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 15px;
  }
}

