.object-details-container {
  padding: 15px 30px;
}

.page-header-container {
  position: fixed;
  left: 24px;
  right: 24px;
  top: 64px;
  padding-top: 15px;
  z-index: 1;
}

.page-content-container {
  margin-top: 115px;
}

.page-title-container {
  margin-top: 15px;

  .page-title {
    font-size: 36px;
    font-weight: bold;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: fit-content;
  }
}

.object-id {
  font-size: 16px;
  color: #707070;
  font-weight: normal;
}

.estimated-value-stats {
  color: #B2B2B2;
  font-size: 13px;
}

.estimated-value-amount {
  color: #029BFE;
  font-size: 16px;
  font-weight: bold;
  margin-left: 8px;
}

.action-buttons-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;

  .assessment-info-icon {
    color: #707070;
    font-size: 20px;
    margin-right: 10px;
  }

  #request-assessment-btn {
    min-width: 145px;
    height: 30px;
    font-size: 16px;
    font-weight: bold;
  }
}

.small-action-buttons-container {
  margin-left: 35px;

  button {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.MuiTooltip-tooltip {
  color: #b4b4b4!important;
  background-color: #383838!important;
  font-size: 13px!important;
  max-width: 180px!important;
  padding: 14px 8px!important;
}

.MuiTooltip-arrow {
  color: #383838!important;
}

.MuiInputBase-multiline {
  padding: 10px!important;
}

.object-description {
  font-size: 13px!important;
  overflow: auto!important;
  padding-right: 10px!important;
  word-break: break-all;
}

@media (max-height: 1366px) {
  .object-description {
    max-height: 200px;
  }
}

@media (max-height: 800px) {
  .object-description {
    max-height: 200px;
  }
}

.document-icon {
  margin: 7px 0;
  color: #B2B2B2;
  cursor: pointer;

  &:hover {
    color: #029BFE;
  }
}

@media (max-width: 960px) {
  .action-buttons-container {
    justify-content: flex-start;
    margin-bottom: 10px;
  }
}

@media (max-width: 960px) {
  .page-header-container {
    position: unset;
    left: unset;
    right: unset;
    top: unset;
  }

  .page-content-container {
    margin-top: 0;
  }

  .page-title-container .page-title {
    font-size: 20px;
  }
}
