.current-user-profile-container {

  .user-profile-left {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    min-height: 200px;
    padding-right: 30px;
    border-right: 1px solid;
    margin-top: 50px;
  }

  .user-profile-lightbox {
    display: flex;
    align-items: flex-end;

    .user-name {
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 10px;
      line-height: 1;
      word-break: break-word;
    }

    .avatar-placeholder {
      width: 160px;
      height: 160px;
      color: #FE6502;
      margin-bottom: -10px;
      min-width: unset;
    }

    .user-avatar {
      width: 160px;
      height: 160px;
      border-radius: 150px;
    }

    #edit-avatar-btn {
      position: absolute;
      bottom: 5px;
      right: 25px;
      background-color: #707070;
      color: #EFEFEF;
      min-width: unset!important;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    .lightbox-details {
      text-align: left;
      margin-left: 25px;
    }

    .stats {
      font-size: 16px;
      line-height: 1;
      margin-bottom: 5px;

      .value {
        font-weight: bold;
        font-size: 22px;
      }
    }
  }

  .user-profile-actions {
    text-align: right;
    padding-top: 50px;
    padding-bottom: 20px;

    #cancel-edit-profile-btn {
      background-color: #707070;
      color: white;
    }

    #save-profile-btn {
      svg {
        width: 20px;
        margin-right: 5px;
        margin-bottom: 2px;
      }
    }

    button {
      height: 28px;
      font-size: 16px;
    }
  }

  .user-profile-form {

    .MuiFormControl-root {
      margin-bottom: 10px;
    }

    #email, #password {
      color: #B2B2B2;
    }

    #change-password-link {
      font-size: 15px;
      font-weight: bold;
      color: #FE6502;
      text-decoration: underline;
      margin-top: -5px;
      cursor: pointer;
    }

    .label {
      font-size: 16px;
      margin-bottom: 5px;

      &.focus-label {
        color: #FE6502;
      }

      &.error-label {
        color: #B32222;
      }
    }

    .user-form-section-title {
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 3px;
      border-bottom: 2px solid #383838;
      max-width: 120px;
      margin-bottom: 15px;
    }
  }

  @media (min-width: 960px) {
    .user-profile-left {
      position: sticky;
      top: 115px;
    }

    .user-profile-actions {
      position: sticky;
      top: 65px;
      z-index: 1;
    }
  }

  @media (max-width: 600px) {
    .avatar-placeholder {
      width: 90px!important;
      height: 90px!important;
    }

    #edit-avatar-btn {
      position: absolute!important;
      bottom: 5px!important;
      right: 0!important;
      min-width: unset!important;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  @media (max-width: 960px) {
    .user-profile-left {
      justify-content: flex-start;
      border-right: none;
      min-height: unset;
    }

    .user-profile-actions {
      text-align: left;
    }
  }
}






