/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #282831;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.error-message {
  font-size: 0.75rem !important;
  text-align: left!important;
  margin-top: 5px!important;
}
#feedback-form .MuiFormHelperText-root.Mui-error {
  white-space: nowrap;
}
.checkbox-label {
  font-size: 14px!important;
}
a {
  text-decoration: none;
}
p {
  word-break: break-word;
}
.circular-progress {
  width: 20px!important;
  height: 20px!important;
  margin-right: 8px!important;
  color: #ffffff !important;
}
.circular-progress.sm {
  width: 15px!important;
  height: 15px!important;
}
legend {
  width: 0!important;
}
textarea {
  resize: vertical!important;
}
.share-icon {
  transform: scaleX(-1);
}
.object-details-left-side {
  position: sticky;
  top: 180px;
}
@media (max-width: 600px) {
  .object-details-left-side {
    position: unset;
    top: unset;
  }
}
.mt-30 {
  margin-top: 30px!important;
}
