.button-grow {
  height: 28px;
  background-color: #707070!important;
  color: #EFEFEF!important;
  padding-left: 5px!important;
  padding-right: 5px!important;
  min-width: unset!important;

  .share-icon {
    transform: scaleX(-1);
  }

  .edit-icon, .delete-icon, .share-icon, .close-icon, .save-icon {
    font-size: 18px;
  }

  .button-grow-text {
    max-width: 0;
    white-space: nowrap;
    -webkit-transition: max-width 0.7s!important;
    transition: max-width 0.7s!important;
    overflow: hidden;
  }

  &:hover {
    .button-grow-text {
      max-width: 8rem;
    }
  }

  &.highlight-on-hover {
    &:hover {
      background-color: #FE6502!important;
    }
  }
}
