.circular-progress {
  width: 20px!important;
  height: 20px!important;
  margin-right: 8px!important;
  color: #ffffff!important;

  &.sm {
    width: 15px!important;
    height: 15px!important;
  }
}

// TODO: find out if we need this
legend {
  width: 0!important;
}

textarea {
  resize: vertical!important;
}

.share-icon {
  transform: scaleX(-1);
}

// TODO: move this
.object-details-left-side {
  position: sticky;
  top: 180px;
}
@media (max-width: 600px) {
  .object-details-left-side {
    position: unset;
    top: unset;
  }
}
