.cropper-bg, .cropper-modal {
  background-image: none;
  background-color: #333339;
}

.cropper-line {
  width: 2px;
}

.cropper-view-box {
  outline-color: white;
  box-shadow: 0px 3px 6px #00000029;
}

.cropper-line, .cropper-point {
  background-color: white;
}

.cropper-point {
  width: 7px;
  height: 7px;
}
