.photo-image-auth {
  width: 50%;
  height: 100vh;
  top: 0;
  bottom: -15px;
  position: fixed;
  object-fit: contain;
  background-color: #060807;
}

.copyright-photo-text {
  position: fixed;
  bottom: 55px;
  left: 30px;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    color: #FE6502;
    margin-left: 7px;
    width: 20px;
    height: 20px;
  }
}


@media (max-width: 1280px) {
  .photo-image-auth {
    width: 41.666667%;
  }
}
