@media (max-width: 600px) {

  .page-header-container {
    div:first-child {
      width: 100%;
      left: 0;
      right: 0;
    }

    h6, h5 {
      padding-left: 15px;
    }

    .actions {
      padding-right: 15px;
    }

    h5 {
      text-align: left;
    }
  }
}
