@import "external-auth";
@import "photo-area";

.auth-container {
  max-width: 515px;
  margin: auto;
  text-align: center;

  .p-content-container {
    padding-top: 65px;
  }

  .divider-box {
    width: 75%;
    margin: 20px auto;
  }

  .auth-form {
    padding-top: 7%;
  }

  .auth-button {
    font-size: 18px!important;
    margin-top: 8%;
    height: 50px;
  }

  .switch-auth-view {
    margin-top: 10%;

    .text {
      font-size: 14px;
      margin-bottom: 20px;
    }

    button {
      height: 50px;
      margin-bottom: 15px;
    }
  }
}

.create-account-container {
  .auth-form {
    padding-top: 0;
  }

  .auth-button {
    margin-top: 8px;
  }

  .switch-auth-view {
    margin-top: 5%;
  }
}

.MuiFormLabel-root.MuiInputLabel-formControl {
  font-size: 17px;
}

.MuiInputLabel-outlined.MuiInputLabel-formControl {
  transform: translate(14px, 12px) scale(1);
}

.MuiInputLabel-outlined.MuiInputLabel-formControl.MuiInputLabel-shrink {
  transform: translate(0, -20px) scale(0.85);
}

.MuiOutlinedInput-root input {
  padding: 10.5px 14px;
}

.MuiFormHelperText-root.Mui-error {
  margin-left: 0;
}

.auth-page-subtitle {
  color: #acacac!important;
  font-size: 18px!important;
  font-family: Myriad Pro Light, serif!important;
  padding-bottom: 20px!important;
  margin-top: 10px!important;
}

@media (max-width: 600px) {
  .auth-container {
    max-width: 100%;
  }
}

@media (max-width: 600px) {
  .auth-page-subtitle {
    font-size: 11px!important;
  }
}
