@import "object-card";

.change-grid-style-container {
  margin-top: 15px;

  svg {
    fill: #707070;
    cursor: pointer;
    width: auto;
    height: 13px;

    &:hover, &.selected {
      fill: #B2B2B2;
    }
  }

  svg:not(:first-child) {
    margin-left: 25px;
  }
}

.gallery-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -0.5%;
  margin-right: -0.5%;
}

.MuiPaginationItem-rounded {
  &.Mui-selected {
    background-color: #2B2B33!important;
    color: white;
  }
}

.card-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.object-actions {
  display: none;
}

.card-link {
 // margin-bottom: 0;
  .object-card-root, .root, .shared-with-me-card {
    width: 100%;
    margin-bottom: 0;
  }
}

.object-card-root:hover {
  .object-actions {
    display: unset;
  }
}

.action {
  width: 28px!important;
  height: 28px!important;
  background-color: #707070!important;
  min-width: unset!important;
  position: absolute!important;
  top: 15px!important;
  opacity: 0.8!important;

  svg {
    color: #EFEFEF;
  }
}

.action-delete {
  right: 15px!important;
}

.action-share {
  transform: scaleX(-1);
}
