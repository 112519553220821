.artifact-gallery {

  max-width: 70vw;
  display: flex;
  align-items: flex-start;

  .current-photo {
    display: flex;
    align-items: center;
    background-color: #383838;
    overflow: hidden;
    width: 50vw;
    height: 64vh;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 1s ease;
    }
  }

  .carousel {

    max-height: 64vh;
    overflow: auto;
    min-width: 119px;

    .carousel-img {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      img {
        height: 100px;
        width: 100px;
        margin: auto;
        object-fit: cover;
        transition: all 1s ease;
      }

      &.thumbnail-action:hover {
        padding: 5px;
        display: grid;

        .thumbnail-action-btn {
          display: flex;
          max-width: 100px;
          margin: auto;
          min-width: 100px;
        }
      }
    }

    .thumbnail-action-btn {
      display: none;
    }

    .selected-photo {
      img {
        border: 2px solid #029BFE;
      }
    }

    .left-arrow {
      position: absolute;
      height: 30px;
      width: 24px;
      background-color: #029BFE;
      border-radius: 6px;
      left: 13px;
      align-items: center;
      cursor: pointer;
      display: none;

      svg {
        width: 24px;
        margin: auto;
      }
    }

    .right-arrow {
      position: absolute;
      height: 30px;
      width: 24px;
      background-color: #029BFE;
      border-radius: 6px;
      align-items: center;
      cursor: pointer;
      display: none;

      svg {
        width: 23px;
        margin: auto;
      }
    }
  }

  .open-photo-lightbox {
    position: relative;
    bottom: -145px;
    right: 30px;
    cursor: pointer;
    width: 0;
  }

  ::-webkit-scrollbar {
    height: 10px!important;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }

  .no-photo-text {
    margin: auto;
    color: gray;
    font-size: 13px;
  }
}

.photo-lightbox-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .iiz--drag {
    width: auto;
  }

  .iiz__img, .iiz__zoom-img {
    margin: auto;
  }

  .iiz__img {
    width: 90vw;
    height: 95vh;
    object-fit: contain;
  }

  .photo-lightbox-paper {
    background-color: #ffffff;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border: none;
    outline: none;
    padding: 0;
    width: 95%;
    height: 95%;
    overflow: auto;
  }

  .prev-photo, .next-photo {
    width: 35px;
    height: 40px;
    background-color: #00000067;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: auto;

    svg {
      width: 35px;
    }
  }

  .prev-photo {
    position: absolute;
    top: 50%;
    left: 3%;
  }

  .next-photo {
    position: absolute;
    top: 50%;
    right: 3%;
  }

  .lightbox-photo {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .zoom-slider {
    width: 200px;
    position: absolute;
    bottom: 14%;
  }
}

.react-transform-component {
  width: 90%!important;
  height: 100%!important;
  margin: auto!important;
}

.ReactModal__Overlay {
  z-index: 1210!important;
}

.ril-outer {
  background-color: white!important;
}

