.external-auth-btn {
  height: 50px;
}

#continue-with-google-btn {
  color: #707070;
  background-color: white;
  line-height: 1;
  margin-right: 10px;

  img {
    width: 24px;
  }
}

#continue-with-facebook-btn {
  color: #FFFFFF;
  background-color: #006CEB;
  line-height: 1;
  margin-right: 10px;

  .fa-facebook {
    font-size: 24px;
  }
}

#continue-with-apple-btn {
  color: #FFFFFF;
  background-color: black;
  line-height: 1;

  img {
    width: 24px;
  }
}
