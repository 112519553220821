.root {
  width: 15.66%;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 35px;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.media {
  height: 260px;
}

.card-title {
  font-size: 16px;
  margin-bottom: 5px;
}

.label {
  color: #B2B2B2;
  font-size: 13px;
}

.value {
  color: #029BFE;
  font-size: 16px;
  font-weight: 700;
  margin-left: 7px;
}

.collector-card, .expert-card {
  box-shadow: none!important;

  .MuiCardContent-root {
    padding-bottom: 10px!important;
  }
}

.expert-card {
  width: 49%!important;
}

@media (max-width: 1920px) {
  .root {
    width: 19%;
  }
}

@media (max-width: 1280px) {
  .root {
    width: 32.33%;
  }
  .expert-card {
    width: 100%!important;
  }
}

@media (max-width: 960px) {
  .root {
    width: 49%;
  }
  .expert-card {
    width: 100%!important;
  }
}

@media (max-width: 600px) {
  .root {
    width: 99%;
  }
  .expert-card {
    width: 100%!important;
  }
}


