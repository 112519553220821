.object-labels-title {
  color: #B2B2B2;
  font-size: 14px!important;
  margin-top: 10px!important;
  margin-bottom: 5px!important;
}

.object-label {
  background-color: #707070;
  color: #EFEFEF;
  font-size: 14px;
  border-radius: 2px;
  padding: 5px 10px;
  margin-right: 8px;
  margin-bottom: 10px;
  display: inline-block;
  line-height: 1;

  .remove-label {
    width: 13px;
    height: 13px;
    margin-left: 10px;
    margin-bottom: -2px;
    cursor: pointer;
  }
}
